
















































































































































































  .form-container {
      max-height: 100vh;
      overflow: auto;
  }
  .app-name {
      margin-top: 80px;
      margin-bottom: 80px;
  }
  body.screen--xl,body.screen--lg {
      .form-container {
          width: 50%;
      }
  }
  .section.boxed {
  display: flex;
  > div {
    width: 90vw;
    margin: auto;
  }
}
